@import '../../styles/variables';

.footer {
  background-color: $footerBackground;
  padding: 80px 125px;
  @media only screen and (max-width: $extraLargeDisplay) {
    padding: 34px 70px;
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 25px 30px;
  }
}

.logo {
  filter: brightness(9);
  margin-bottom: 25px;
  @media only screen and (max-width: $smallDisplay) {
    width: 165px;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.linkWrapper {
  margin-bottom: 20px;
  position: relative;
  text-decoration: none;
}

.link {
  text-decoration: none;
  color: $whiteColor;
  font-size: 16px;
}

.col {
  padding-bottom: 20px;

  &:nth-child(1) {
    width: 33%;
    @media only screen and (max-width: $mediumDisplay) {
      width: 33%;
    }
    @media only screen and (max-width: $smallDisplay) {
      width: 50%;
    }
  }

  &:nth-child(2) {
    width: 33%;
    @media only screen and (max-width: $mediumDisplay) {
      width: 33%;
    }
    @media only screen and (max-width: $smallDisplay) {
      width: 50%;
    }
  }

  &:nth-child(3) {
    width: 33%;
    padding: 0 20px;
    @media only screen and (max-width: $mediumDisplay) {
      width: 33%;
      padding: 0;
    }
    @media only screen and (max-width: $smallDisplay) {
      width: 100%;
    }
  }
}

.iconWithText {
  display: flex;
  margin-bottom: 20px;

  img {
    height: fit-content;
    @media only screen and (max-width: $smallDisplay) {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    svg path {
      fill: $accentColor;
    }
  }
}

.text {
  padding-left: 10px;
  font-size: 15px;
  color: $whiteColor;
}

.address {
  display: flex;
  align-items: center;
}

.newsletter {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
  margin-top: 25px;
}

.input {
  padding: 17px;
  border: none;
  outline: none;
  border-radius: 4px;
  margin-right: 10px;
  width: 100%;

  &::placeholder {
    color: $placeHolderColor;
  }
}

.button {
  font-size: 12px;
  padding: 0 10px;
}

.subscribeText {
  margin-bottom: 15px;
  display: block;
  color: $whiteColor;
  font-size: 15px;
}

.socials {
  color: $whiteColor;
  font-size: 15px;
}

.socialIcon {
  display: inline-block;
  margin-top: 15px;

  img {
    width: 50px;
    height: 50px;
    @media only screen and (max-width: $smallDisplay) {
      width: 45px;
      height: 45px;
    }
  }

  &:nth-child(2) {
    margin: 0 10px;
    padding: 0 8px;
  }

  &:nth-child(3) {
    margin: 0 15px 0 0;
  }

  &:hover {
    svg path {
      fill: $accentColor;
    }
  }
}

.reserved {
  text-align: center;
  color: $whiteColor;
  margin-top: 25px;
  padding: 20px 0 0;
}

.text {
  font-size: 16px;
  font-weight: bold;
  @media only screen and (max-width: $smallDisplay) {
    font-size: 14px;
  }
  a {
    color: $whiteColor;
    text-decoration: none;
  }
}

.subtitle {
  margin-bottom: 5px;
  color: $whiteColor;
  padding-left: 10px;
  @media only screen and (max-width: $smallDisplay) {
    font-size: 14px;
  }
}

.created {
  display: flex;
  justify-content: center;
  color: $placeHolderColor;
  text-decoration: none;
  font-size: 14px;
  width: 350px;
  margin: auto;
}

.heart {
  width: 15px;
  height: 15px;
  margin: 0 10px;

  svg {
    width: 100%;
  }
}

.followUsTitle {
  font-size: 18px;
  font-weight: bold;
}