$whiteColor: #FFFFFF;
$blackColor: #000000;
$logoColor: #706D6E;
$menuItemLinkColor: #707070;
$grayColor: #535353;
$borderColor: #D9D9D9;
$brandItemBorderColor: #E9E9E9;
$darkBorderColor: #E1E1E1;
$darkGreyColor: #505150;
$greyTextColor: #676767;
$productCodeColor: #9E9E9E;
$shadowColor: #e6e6e6;
$boxColor: #6E6E6E;
$accentColor: #BCD5AC;
$accentColorDark: #92a585;
$errorColor: #f86565;
$transparentWhiteColor: rgba(255, 255, 255, 0.7);
$placeHolderColor: #86898B;
//$backgroundColor: #F8F9FA;
$backgroundColor: #fff;
$lightGrayColor: #F6F7F7;

$titleColor: #505150;
$textColor: #505150;
$secondaryTitleColor: #445964;
$gradient: linear-gradient(89.73deg, #BCD5AC 0.16%, rgb(188, 213, 172) 99.72%);

$footerBackground: #505150;

$dateBorderColor: #C6D9D6;

$descriptionColor: #86898B;
$cardTextColor: #263138;
$midGrey: #4D4D4D;
$addToCartBgColor: #F9F9F9;
$productColor: #D6D6D6;
$subTitleColor: #757575;
$addToCartTextColor: #C2C2C2;
$transparentWhiteColor: rgba(255, 255, 255, 0.7);
$extraExtraLargeDisplay: 1920px;
$extraLargeDisplay: 1600px;
$largeDisplay: 1200px;
$mediumDisplay: 992px;
$smallDisplay: 767px;
$extraSmallDisplay: 479px;
$extraExtraSmallDisplay: 375px;
$fontBold: Roboto-bold, Arial;
$fontRegular: Roboto, Arial;
$fontLight: Barlow, sans-serif, Arial;
$fontMedium: Roboto-Medium, Arial;

$disabledColor: #999999;
$boxshadow: 0px 1px 30px #E4E5E8;;
$checkbox: '✔';

$labelColor: #757575;
$errorBgColor: #ffe0d7;


$adminBgColor: #F6F7FB;
$drawerBgColor: #ffffff;
$tdBgColor: #ffffff;
$thBgColor: #F0F5F9;
$thTextColor: #2C2C2C;
$formBgColor: #ffffff;
$adminTextColor: #757575;
$filterBgColor: #F8F8F8;
$filterTextColor: #707070;
$filterBgActiveColor: #E8E8E8;
$buttonGreen: #43B98D;
$tableBorderColor: #e0e0e0;
$adminBoxShadow: 2px 2px 14px rgba(87, 87, 102, 0.25);
$fieldBorderColor: #ced4da;
$boxshadowColor: rgba(0, 0, 0, .3);
