@import "src/styles/variables";
.root{

    margin: 50px 0 0;
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    padding: 10px 0;
}

.image{
    max-width: 100%;
    height: 115px;
    object-fit: contain;
}
