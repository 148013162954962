@import "src/styles/variables";

.root{
  margin-top: 50px;
  padding: 165px 120px 90px;
  line-height: 1.7;
  @media only screen and (max-width: $mediumDisplay) {
    padding: 165px 10% 30px;
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 165px 20px 30px;
  }
  ul{
    padding-left: 60px;
  }
  h3{
    padding-top: 10px;
  }
}