@import "src/styles/variables";

.root {
    background-color: $backgroundColor;
    padding-bottom: 50px;
}

.title {
    text-align: center;
    color: $titleColor;
    font-size: 30px;
    position: relative;
    margin: 60px auto auto;
    display: table;
    font-weight: bold;
    @media only screen and (max-width: $smallDisplay) {
        font-size: 25px;
        margin: 20px auto auto;
    }
    //&:before {
    //    content: "";
    //    width: 50px;
    //    height: 3px;
    //    background-color: $accentColor;
    //    position: absolute;
    //    left: -70px;
    //    top: 50%;
    //    @media only screen and (max-width: $smallDisplay) {
    //        width: 20px;
    //        left: -30px;
    //    }
    //}
    //&:after {
    //    content: "";
    //    width: 50px;
    //    height: 3px;
    //    background-color: $accentColor;
    //    position: absolute;
    //    right: -70px;
    //    top: 50%;
    //    @media only screen and (max-width: $smallDisplay) {
    //        width: 20px;
    //        right: -30px;
    //    }
    //}
}

.description {
    font-size: 18px;
    text-align: center;
    color: $descriptionColor;
    max-width: 600px;
    margin: 30px auto auto;
    @media only screen and (max-width: $smallDisplay) {
        font-size: 14px;
        margin: 20px auto;
    }
}

.benefits{
    padding: 10px 120px;
    text-align: center;
    @media only screen and (max-width: $mediumDisplay) {
        padding: 10px 10%;
    }
    @media only screen and (max-width: $smallDisplay) {
        padding: 10px 20px;
    }
}


.benefitsWrapper{
    padding: 70px 0 40px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 30px;
    @media only screen and (max-width: $extraLargeDisplay) {
        padding: 40px 0;
        grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: $mediumDisplay) {
        padding: 40px 0;
        grid-template-columns: repeat(3, 1fr);
    }
}

.benefit{
    display: flex;
    flex-direction: column;
    align-items: center;
    .number{
        width: 100px;
        height: 100px;
        font-size: 30px;
        border-radius: 10px;
        background: $accentColor;
        color: $whiteColor;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        @media only screen and (max-width: $largeDisplay) {
            width: 70px;
            height: 70px;
            font-size: 25px;
        }
        @media only screen and (max-width: $mediumDisplay) {
            width: 70px;
            height: 70px;
            font-size: 20px;
        }

    }
    p{
        color: $titleColor;
        font-weight: bold;
        font-size: 20px;
        @media only screen and (max-width: $largeDisplay) {
            font-size: 16px;
        }
    }
}
.aboutus{
    padding: 10px 120px;
    @media only screen and (max-width: $mediumDisplay) {
        padding: 10px 10%;
    }
    @media only screen and (max-width: $smallDisplay) {
        padding: 10px 20px;
    }
}
.text{
    text-align: center;
    margin: 10px 0;
}
.readMore{
    color: $accentColorDark;
    font-weight: bold;
}