@import '../../styles/variables';
.wrapper {
    display: flex;
}

.gray,
.white,
.green {
    padding: 16px 5px;
    text-decoration: none;
    text-align: center;
    font-family: $fontRegular;
    font-size: 17px;
    cursor: pointer;

    &:focus {
        outline: none;
        box-shadow: none
    }
}

button:disabled {
    cursor: default !important;
}
.primary {
    font-size: 19px;
    padding: 15px 32px;
    margin: 0 0px;
    min-height: 50px;
    background: $accentColor;
    //color: $whiteColor;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: bold;
    color: $textColor;
    @media only screen and (max-width: $largeDisplay) {
        font-size: 18px;
    }
    @media only screen and (max-width: $smallDisplay) {
        font-size: 18px;
    }
    &:focus {
        outline: none;
        box-shadow: none
    }
    &:hover {
        background: $accentColor;
    }
    border-radius: 4px;
}

.secondary {
    font-size: 19px;
    padding: 15px 32px;
    margin: 0 0px;
    min-height: 50px;
    background: $dateBorderColor;
    color: $whiteColor;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    @media only screen and (max-width: $largeDisplay) {
        font-size: 18px;
    }
    @media only screen and (max-width: $smallDisplay) {
        font-size: 18px;
    }
    &:focus {
        outline: none;
        box-shadow: none
    }
    &:hover {
        background-color: $accentColor;
    }
    border-radius: 4px;
}
.blank {
    background-color: transparent;
    font-size: 18px;
    color: grey;
    border: none;
    padding: 0 20px;
    cursor: pointer;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.noPadding {
    font-size: 18px;
    border: none;
    padding: 0 0px;
    cursor: pointer;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.small {
    padding: 5px;
    cursor: pointer;
}
.noPadding {
    padding: 0;
    z-index: 5;
}

.full{
    width: 100%;
}
.link {
    text-decoration: none;

    @media only screen and (max-width: $smallDisplay) {
        font-size: 12px;
    }

    &:focus {
        outline: none;
        box-shadow: none
    }
}
.white {
    color: $grayColor;
    background-color: $whiteColor;
    border: 1px solid $grayColor;
    font-family: $fontBold;
    font-size: 18px;

    &:hover {
        color: $whiteColor;
        background-color: $grayColor;
        border: 1px solid $whiteColor;
    }

    @media only screen and (max-width: $smallDisplay) {
        font-size: 16px;
    }
    @media only screen and (max-width: $extraSmallDisplay) {
        font-size: 10px;
    }
}

.gray {
    color: $whiteColor;
    background-color: $grayColor;
    border: 1px solid $grayColor;
    font-family: $fontBold;
    font-size: 18px;

    &:hover {
        color: $grayColor;
        background-color: transparent;
        border: 1px solid $grayColor;
    }

    @media only screen and (max-width: $smallDisplay) {
        font-size: 16px;
    }
    @media only screen and (max-width: $extraSmallDisplay) {
        font-size: 10px;
    }
}
.round {
    border-radius: 50px;
    box-shadow: 0 0 5px 0 $grayColor;
}
