@import "src/styles/variables";

.root{
  margin-top: 50px;
  padding: 190px 120px 90px;
  line-height: 1.7;
  @media only screen and (max-width: $mediumDisplay) {
    padding: 190px 10% 30px;
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 190px 20px 30px;
  }
}