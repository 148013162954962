@import "src/styles/variables";

.root{
  padding: 165px 120px 90px;
  line-height: 1.7;
  display: flex;
  justify-content: space-between;
  min-height: 70vh;
  @media only screen and (max-width: $mediumDisplay) {
    padding: 165px 10% 30px;
  }
  @media only screen and (max-width: $smallDisplay) {
    flex-direction: column;
    padding: 165px 20px 30px;
  }
}
.map {
  min-height: 240px;
  position: relative;
  margin-right: 20px;
  width: calc(90% - 20px);
}
.info{
  width: 50%;
  @media only screen and (max-width: $smallDisplay) {
    margin-top: 30px;
    width: 100%;
  }
}
.iconWithText {
  display: flex;
  margin-bottom: 20px;
  img{
    height: fit-content;
  }
  &:hover {
    svg path {
      fill: $accentColor;
    }
  }
}
.text {
  padding-left: 10px;
  font-size: 15px;
  color: $darkGreyColor;
}
.address {
  display: flex;
  align-items: center;
}
.text {
  font-size: 16px;
  font-weight: bold;
  a {
    color: $darkGreyColor;
    text-decoration: none;
  }
}
.subtitle{
  color: $darkGreyColor;
  padding-left: 10px;
}